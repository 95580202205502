import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Learnings and tools
// Branding, Adobe Illustrator, Adobe After Effects, Serigraphy
import { ReactComponent as BrandingIcon } from './svg/branding.svg';
import { ReactComponent as IllustratorIcon } from './svg/illustrator.svg';
import { ReactComponent as AftereffectsIcon } from './svg/aftereffects.svg';
import { ReactComponent as SerigraphyIcon } from './svg/serigraphy.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import ExternalLink from '@common/ExternalLink';
// import DemoLink from '@common/DemoLink';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  LearningsGrid,
  Learning,
  LearningTopic,
  // Element,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './branding-a-running-crew.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/branding-a-running-crew/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/branding-a-running-crew/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>

              <Paragraph>{description}</Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="The branding of Flames Barcelona"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <BrandingIcon />
                  <LearningTopic>Branding</LearningTopic>
                </Learning>
                <Learning>
                  <IllustratorIcon />
                  <LearningTopic>Adobe Illustrator</LearningTopic>
                </Learning>
                <Learning>
                  <AftereffectsIcon />
                  <LearningTopic>Adobe After Effects</LearningTopic>
                </Learning>
                <Learning>
                  <SerigraphyIcon />
                  <LearningTopic>Serigraphy</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Paragraph>
                Since I co-founded Flames Barcelona back in 2018 I've been able
                to experiment and play with digital assets (social media
                content, animated gifs, mailings, websites), audiovisual assets
                (video and photography) and physical assets (casual clothing,
                racing clothing, stickers, badges, magnets, keychains, flags) to
                establish a brand.
              </Paragraph>

              <Paragraph>
                I crafted campaings and activations to build experiences for our
                runners, at events and for seasonal occasions.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="moodboard-1"
                alt="Photograps from Flames Barcelona runners"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="moodboard-2"
                alt="Photograps from Flames Barcelona runners"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="moodboard-3"
                alt="Photograps from Flames Barcelona runners"
                overflow
              />

              <Title>Brand</Title>
              <Paragraph>
                The first thing we had to set up were, obviously, naming and
                marks.
              </Paragraph>

              <List>
                <Item>
                  It had to reflect <b>our values and who we are</b>:
                  urban-born, somewhat underdogs and misfits, totally amateurs,
                  not too serious about training and very fond of the beer
                  after, yet aggressively competitives in our own range,
                  community-driven, family
                </Item>
                <Item>
                  It should <b>differentiate</b> from the usual terms that a
                  running club or an athletics team use
                </Item>
                <Item>
                  We only wante to be a <b>group of friends</b> with a name
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="brand"
                alt="Different tests for the brand"
                overflow
              />

              <List>
                <Item>
                  We had a clear idea that <b>"Barcelona"</b> should be part of
                  the naming because its international recongnition would help
                  our placement wit the international running crew community
                </Item>
                <Item>
                  The initial exploration between the 3 founders started from
                  the concepts <b>"running"</b> and <b>"crew"</b>, with their
                  intersection and around Barcelona
                </Item>
                <Item>
                  "Flames Barcelona" wasn't even a possibility on the firsts
                  brainstorms, and for a while we were planning to go forwards
                  with another name (that didn't feel right when presented to
                  the whole community)
                </Item>
              </List>

              <Paragraph>
                Once the brand was settled, the first application that we needed
                was the race singlet. The uniform.
              </Paragraph>

              <Paragraph>
                Based on the male / female race kits we procured, I tested
                different designs until settling on a candidate and then
                explored different color variations:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="singlets-wip"
                alt="Different variations for the race singlets"
                overflow
              />

              <Image
                edges={data.designs.edges}
                name="singlets"
                alt="The first ever race singlets from Flames Barcelona"
                description="First-edition race singlets"
                overflow
              />

              <Title>Physical</Title>
              <Paragraph>
                Since we were established the Flames Barcelona brand has seen
                many physical applications. Some of them have been{' '}
                <b>produced by professionals</b>, and some of them have been{' '}
                <b>home-made</b>.
              </Paragraph>
              <Paragraph>I've developed thins like:</Paragraph>
              <List>
                <Item>
                  All kinds of stickers (rectangular, circular, with glossy and
                  holographic finish...)
                </Item>
                <Item>Keychains</Item>
                <Item>Fridge magnets</Item>
                <Item>Patches</Item>
                <Item>Buttons</Item>
                <Item>Flags</Item>
                <Item>Race shirts</Item>
                <Item>Casual t-shirts</Item>
                <Item>Sweatshirts</Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="physical"
                alt="Some pictures of Flames Barcelona swag"
                description="Race kits in the making, plus some swag"
                overflow
              />

              <Image
                edges={data.designs.edges}
                name="physical-2"
                alt="Some pictures of Flames Barcelona swag"
                description="Shirts for singular races"
                overflow
              />

              <Image
                edges={data.designs.edges}
                name="physical-3"
                alt="Some pictures of Flames Barcelona swag"
                description="Designs ready to print"
                overflow
              />

              <Title>Digital</Title>
              <Paragraph>
                Most of the daily interaction with our runners and with the
                global running community happens in the form of{' '}
                <b>digital content</b> on social networks, primarily{' '}
                <ExternalLink href="https://instagram.com/flamesbarcelona">
                  Instagram
                </ExternalLink>
                . That includes building anticipation, clearly communicating
                information, sharing content and debriefing on results.
              </Paragraph>

              <Paragraph>
                For example, I designed digital content for events such as:
              </Paragraph>

              <Paragraph>
                During Barcelona's Half Marathon we hosted a{' '}
                <b>
                  weekend event for 450+ people, from 39 crews and 18 countries
                </b>
                . At the intersection between running culture, lifestyle, music,
                art and creativity these events happen around the globe for
                crews to come together to meet, run, create and party together.
              </Paragraph>
              <List>
                <Item>
                  Announcement of the event, prices, sign-up of international
                  crews, deadlines and reminders
                </Item>
                <Item>
                  Detailed instructions during the weekend for all activities
                </Item>
                <Item>Success stories afterwards</Item>
              </List>

              <Paragraph>
                On Internation Women's Day, we supported 4 of our women who
                designed a special social session for{' '}
                <b>100 women joining the #WomenInCities campaign</b>. A symbolic
                action to raise awareness on gender equality issues and the
                place of women in cities by highlighting the small amount of
                streets named for them.
              </Paragraph>
              <List>
                <Item>Filling our profile with female runners</Item>
                <Item>
                  Motivation and awareness, co-creation of part of the event,
                  sign-ups to get to 100 participants
                </Item>
                <Item>Real-time sharing of the event</Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="events-1"
                alt="Screenshots of stories for events"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="events-2"
                alt="Screenshots of stories for events"
                overflow
              />

              <Paragraph>
                On March 2020, much like the rest of the world, we went into
                lockdown. For a 2-month period we tried to have fun and played
                with the concept of <b>"streaming training sessions"</b>,
                playing with content platforms like Netflix, studios like
                Disney, franchises and properties like Marvel or Mission
                Impossible.
              </Paragraph>
              <List>
                <Item>
                  I developed alternate branding for Flames emulating brands and
                  title cards
                </Item>
                <Item>
                  I crafted short funny stories with alternate subtitles for
                  trailers and clips
                </Item>
              </List>

              <Image
                edges={data.designs.edges}
                name="mission-possible"
                alt="Screenshots of Mission Impossible themed stories during lockdown"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="run-ragnarok"
                alt="Screenshots of Thor Ragnarok themed stories during lockdown"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="stay-home"
                alt="Screenshots of Star Wars and Thor Ragnarok themed stories during lockdown"
                overflow
              />

              <Paragraph>
                Our weekly zoom trainings were branded as{' '}
                <b>"Stranger Trainings"</b>:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="stranger-trainings-1"
                alt="Screenshots of Stranger Things themed stories during lockdown"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="stranger-trainings-2"
                alt="Screenshots of Stranger Things themed stories during lockdown"
                overflow
              />

              <Paragraph>
                To establish a more permanent and traditional presence, I
                developed a website (
                <ExternalLink href="https://flamesbarcelona.com">
                  flamesbarcelona.com
                </ExternalLink>
                ) with{' '}
                <ExternalLink href="https://www.gatsbyjs.com/">
                  Gatsby
                </ExternalLink>{' '}
                and{' '}
                <ExternalLink href="https://www.netlifycms.org/">
                  Netlify CMS
                </ExternalLink>{' '}
                were we could explain what is Flames Barcelona and publish{' '}
                <b>blog posts</b> about our journey:
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="web-1"
                alt="Screenshot of the current homepage for flamesbarcelona.com"
                description="flamesbarcelona.com"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="web-2"
                alt="Screenshots of contents at flamesbarcelona.com"
                overflow
              />
            </Content>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
